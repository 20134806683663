//MUI
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./ModalMotivoCancelamento.scss";
import { useLoading } from "../../../../../../../shared/context/LoadingContext";
import { useHttpClient } from "../../../../../../../shared/hooks/http-hook";

import PageviewIcon from "@mui/icons-material/Pageview";

const motivosCancelamento = [
  { cod: 2, description: "Retificação - Art.º 65, n.º 2 CIVA" },
  { cod: 3, description: "Retificação - Art.º 65, n.º 3 CIVA" },
  { cod: 6, description: "Retificação - Art.º 65, n.º 6 CIVA" },
  { cod: 7, description: "Retificação - Art.º 65, n.º 7 CIVA" },
  { cod: 8, description: "Retificação - Art.º 65, n.º 8 CIVA" },
  { cod: 9, description: "Retificação - Art.º 65, n.º 9 CIVA" },
  { cod: 0, description: "Outro" },
];

function ModalMotivoCancelamento({
  open,
  handleCloseModal,
  modalData,
  handleCancelarRecebimento,
  handleCancelarRecebimentoEntrada,
  clinica,
  auth,
}) {
  const requiresNifNome =
    modalData.tipoDocumentoAnular === "TVE" &&
    (!modalData.nif ||
      modalData.nif === "000000000" ||
      modalData.nif === "999999999");

  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [motivoCancelamento, setMotivoCancelamento] = useState(
    motivosCancelamento[0]
  );
  const [erro, setErro] = useState("");
  const [outro, setOutro] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [nome, setNome] = useState(modalData.nome || "");
  const [nif, setNif] = useState(modalData.nif || "000000000");

  const motivosCancelamentoChangeHandler = (e) => {
    const selectedMotivo = motivosCancelamento.find(
      (m) => m.cod === parseInt(e.target.value)
    );
    setMotivoCancelamento(selectedMotivo);
  };

  const beforeSubmitHandler = () => {
    if (
      motivoCancelamento.cod === 0 &&
      motivoCancelamento.description.trim().length < 10
    ) {
      setErro(
        `Motivo de emissão da ${modalData.tipoDocumento} teve ter 10 ou mais caracteres!`
      );
      //Validar para caso seja necessário introduzir dados de cancelamento do NCE
    } else if (requiresNifNome) {
      if (
        !nif ||
        nif?.toString()?.trim() === "000000000" ||
        nif?.toString()?.trim() === "999999999"
      ) {
        setErro(
          "É necessário um NIF válido para a emissão do documento eletrónico!"
        );
      } else if (!nome || nome?.trim() === "") {
        setErro(
          "É necessário um Nome válido para a emissão do documento eletrónico!"
        );
      } else {
        setIsSubmitting(true);
        submitHandler();
      }
    } else {
      setIsSubmitting(true);
      submitHandler();
    }
  };

  const submitHandler = () => {
    setIsSubmitting(true);

    if (modalData.tipoCancelamento === "entrada") {
      handleCancelarRecebimentoEntrada(
        modalData.entrada,
        true,
        motivoCancelamento.cod === 0
          ? {
              cod: motivoCancelamento.cod,
              description: outro.trim(),
            }
          : motivoCancelamento,
        modalData.tipoDocumento,
        requiresNifNome
          ? { nome: nome?.trim(), nif: nif?.toString()?.trim() }
          : null
      );
    } else {
      handleCancelarRecebimento(
        modalData.id,
        motivoCancelamento.cod === 0
          ? {
              cod: motivoCancelamento.cod,
              description: outro.trim(),
            }
          : motivoCancelamento,
        modalData.tipoDocumento,
        requiresNifNome
          ? { nome: nome?.trim(), nif: nif?.toString()?.trim() }
          : null
      );
    }
    /////////////////////////////////
    setIsSubmitting(false);
    handleCloseModal();
  };

  const findClientInfo = async (nif, nomeToCheck) => {
    const formData = new FormData();
    nomeToCheck && formData.append("nome", nomeToCheck);
    nif && formData.append("nif", nif);
    formData.append(
      "clienteSecret",
      clinica.clinica.dadosEmissor.cliente_oauth_secret
    );
    formData.append("clienteId", clinica.clinica.dadosEmissor.cliente_id);
    try {
      startLoading();
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/nifbinome`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      stopLoading();

      if (response.payload.length === 0) {
        setErro("Nenhum cliente encontrado");
      } else if (response.payload.length === 1) {
        setNome(response.payload[0].Name);
        setNome(response.payload[0].Name);
        if (!nome || nome.trim() === "") {
          setNome(response.payload[0].Name);
        }
        setNif(response.payload[0].TaxId);
      } else {
        // Caso encontrar mais do que um cliente
      }
    } catch (error) {
      stopLoading();
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box motivoCancelamento__modal"
          id="modal__motivoCancelamento"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Motivo emissão de {modalData.tipoDocumento} para cancelamento de{" "}
            {modalData.tipoDocumentoAnular}
          </h1>

          <div className="motivoCancelamento__modal__container">
            <select
              onChange={motivosCancelamentoChangeHandler}
              className="motivoCancelamento__modal__select"
            >
              {motivosCancelamento.map((m) => (
                <option key={m.cod} value={m.cod}>
                  {m.description}
                </option>
              ))}
            </select>

            {motivoCancelamento.cod === 0 && (
              <textarea
                className="motivoCancelamento__modal__textarea"
                cols={4}
                rows={4}
                onChange={(e) => setOutro(e.target.value)}
                value={outro}
                placeholder={`Descrição do motivo de emissão da ${modalData.tipoDocumento}`}
              />
            )}

            {requiresNifNome && (
              <>
                <div
                  className="takes2-5 form-input"
                  style={{ marginTop: "25px", marginBottom: "15px" }}
                >
                  <input
                    id="nome"
                    name="nome"
                    type="text"
                    placeholder="Nome Fiscal"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    className="input"
                  />
                  <label className="label" htmlFor="nome">
                    Nome Fiscal
                  </label>
                  <PageviewIcon
                    className="icon-search-field"
                    onClick={findClientInfo.bind(null, null, nome)}
                  />
                </div>

                <div className="form-input takes3-5">
                  <input
                    id="nif"
                    name="nif"
                    type="text"
                    placeholder="1333333"
                    value={nif}
                    onChange={(e) => setNif(e.target.value)}
                    className="input"
                  />
                  <label className="label" htmlFor="nif">
                    Nif
                  </label>
                  <PageviewIcon
                    className="icon-search-field"
                    onClick={findClientInfo.bind(null, nif, null)}
                  />
                </div>
              </>
            )}
          </div>

          <div className="motivoCancelamento__modal__erro">{erro && erro}</div>

          <div
            style={{
              gridColumn: "1/-1",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <span
              className="blue-button"
              disabled={isSubmitting}
              onClick={beforeSubmitHandler}
            >
              {isSubmitting ? "Enviando..." : "Enviar"}
            </span>

            <span
              className="cancel-btn"
              onClick={handleCloseModal}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalMotivoCancelamento;
