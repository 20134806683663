import React, { useContext, useEffect, useState, useCallback } from "react";
import Sidebar from "../../shared/components/sidebar/Sidebar";
import Navbar from "../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { ClinicaContext } from "../../shared/context/clinica-context";
import { AuthContext } from "../../shared/context/auth-context";
import { useLoading } from "../../shared/context/LoadingContext";
import moment from "moment";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

//MUI
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import "./Logs.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedValues, theme) {
  return {
    fontWeight:
      selectedValues.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const CATEGORIAS = {
  AGENDA: "Agenda",
  CLIENTE: "Cliente",
  FINANCEIRO: "Financeiro",
  FLUXO_ATENDIMENTO: "Fluxo de Atendimento",
  FUNCIONARIOS: "Funcionários",
  STOCK: "Stock",
  TRATAMENTOS: "Tratamentos",
  CONFIGURACAO: "Configuração",
  UTILIZADORES: "Utilizadores",
};

const SUBCATEGORIAS = {
  // Cliente
  CLIENTE_CADASTRO: "Cadastro de Cliente",
  CLIENTE_AVALIACAO: "Avaliação",
  CLIENTE_ORCAMENTO: "Orçamento",
  CLIENTE_PLANO_TRATAMENTO: "Plano de Tratamento",
  CLIENTE_CONSULTA: "Consulta",
  CLIENTE_ATESTADO: "Atestado",
  CLIENTE_IMPRESSO: "Impresso",
  CLIENTE_PRESCRICAO: "Prescrição",
  CLIENTE_ANEXO: "Anexo",
  CLIENTE_INFORMACOES: "Informações",
  CLIENTE_HISTORICO: "Histórico",

  // Configuração
  CONFIGURACAO_AVALIACAO: "Configuração de Avaliação",
  CONFIGURACAO_CONSULTA: "Configuração de Consulta",
  CONFIGURACAO_VALIDADEPARCELA: "Validade de Parcela",

  // Financeiro
  FINANCEIRO_PAGAMENTO: "Pagamento",
  FINANCEIRO_RECEBIMENTO: "Recebimento",
  FINANCEIRO_CANCELAMENTO: "Cancelamento",
  FINANCEIRO_RELATORIO: "Relatório",
  FINANCEIRO_CONTA_CORRENTE: "Conta Corrente",
  FINANCEIRO_DOCUMENTO_ELETRONICO: "Documento Eletrônico",
  FINANCEIRO_CONFIGURACOES: "Configurações Financeiras",

  // Agenda
  AGENDA_MARCACAO: "Marcação",
  AGENDA_REMARCACAO: "Remarcação",
  AGENDA_CANCELAMENTO: "Cancelamento de Agenda",
  AGENDA_CONFIRMACAO: "Confirmação",

  // Fluxo de Atendimento
  FLUXO_INICIO_ATENDIMENTO: "Início de Atendimento",
  FLUXO_FIM_ATENDIMENTO: "Fim de Atendimento",
  FLUXO_ALTERACAO_STATUS: "Alteração de Status",

  // Funcionários
  FUNCIONARIO_CADASTRO: "Cadastro de Funcionário",
  FUNCIONARIO_PERMISSOES: "Permissões",
  FUNCIONARIO_HORARIO: "Horário",
  FUNCIONARIO_COMISSAO: "Comissão",

  // Stock
  STOCK_ENTRADA: "Entrada de Stock",
  STOCK_SAIDA: "Saída de Stock",
  STOCK_AJUSTE: "Ajuste de Stock",
  STOCK_INVENTARIO: "Inventário",
  STOCK_CONFIGURACOES: "Configurações de Stock",

  // Tratamentos
  TRATAMENTO_CADASTRO: "Cadastro de Tratamento",
  TRATAMENTO_ALTERACAO: "Alteração de Tratamento",
  TRATAMENTO_VALOR: "Valor do Tratamento",
  TRATAMENTO_COMISSAO: "Comissão do Tratamento",

  // Utilizadores
  UTILIZADORES_CADASTRO: "Cadastro de Utilizador",
  UTILIZADORES_STATUS: "Status do Utilizador",
  UTILIZADORES_PERFIS: "Perfis de Utilizador",
  UTILIZADORES_CONFIGURACAO: "Configuração de Utilizador",
};

const LOGS_PER_PAGE = 15;

function Logs() {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [logsData, setLogsData] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [categoriaPrincipal, setCategoriaPrincipal] = useState();
  const [subcategoria, setSubcategoria] = useState();
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const theme = useTheme();

  const LogsPagination = ({ totalPages, page, onPageChange }) => {
    return (
      <div
        className="pagination-container"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={onPageChange}
            color="primary"
            showFirstButton
            showLastButton
          />
        </Stack>
      </div>
    );
  };

  useEffect(() => {
    const fetchUsers = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/ativos/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setAllUsers(
          responseData.users.map((u) => ({ _id: u._id, name: u.name }))
        );
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchUsers();
    fetchLogs(moment(), moment(), null, null, [], 1);
  }, []);

  const fetchLogs = useCallback(
    async (
      startDate,
      endDate,
      categoriaPrincipal,
      subcategoria,
      utilizadores,
      currentPage
    ) => {
      startLoading();
      const formData = new FormData();
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("categoriaPrincipal", categoriaPrincipal);
      formData.append("subcategoria", subcategoria);
      if (utilizadores && utilizadores.length > 0) {
        formData.append("utilizadores", JSON.stringify(utilizadores));
      }
      formData.append("page", currentPage);
      formData.append("limit", LOGS_PER_PAGE);
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clinicalogs/${clinica.clinica._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setLogsData(responseData.logs);
        setTotalPages(responseData.pagination.pages);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    },
    [sendRequest, clinica.clinica._id, auth.token]
  );

  const handleCategoriaChange = (event) => {
    const newCategoria = event.target.value;
    setCategoriaPrincipal(newCategoria);
  };

  const handleSubcategoriaChange = (event) => {
    const newSubcategoria = event.target.value;
    setSubcategoria(newSubcategoria);
  };

  const getFilteredSubcategorias = useCallback(() => {
    if (!categoriaPrincipal) return [];
    return Object.entries(SUBCATEGORIAS).filter(([key]) =>
      key.startsWith(categoriaPrincipal)
    );
  }, [categoriaPrincipal]);

  const changeDatas = useCallback((identificacao, valor) => {
    if (!valor) return; // Prevent null values

    if (identificacao === "startDate") {
      setStartDate(valor);
    } else {
      setEndDate(valor);
    }
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchLogs(
      startDate,
      endDate,
      categoriaPrincipal,
      subcategoria,
      users,
      value
    );
  };

  const handleSearch = () => {
    setPage(1);
    fetchLogs(startDate, endDate, categoriaPrincipal, subcategoria, users, 1);
  };

  const handleUsersChange = (event) => {
    const {
      target: { value },
    } = event;
    setUsers(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleLimpar = () => {
    setStartDate(moment(new Date()));
    setEndDate(moment(new Date()));
    setUsers([]);
    setCategoriaPrincipal();
    setSubcategoria();
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar title="Clientes" />
        <div className="caixa" style={{ margin: "30px" }}>
          <div className="logsHeader">
            <LocalizationProvider dateAdapter={AdapterMoment} className="">
              <DesktopDatePicker
                className=""
                label="Data Inicial*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => changeDatas("startDate", value)}
                value={startDate}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterMoment} className="">
              <DesktopDatePicker
                className=""
                label="Data Final*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => changeDatas("endDate", value)}
                value={endDate}
              />
            </LocalizationProvider>
            {/* Div Vazio adicionado apenas para css */}
            <div></div>

            <FormControl>
              <InputLabel>Categoria</InputLabel>
              <Select
                value={categoriaPrincipal || null}
                label="Categoria"
                onChange={handleCategoriaChange}
              >
                <MenuItem value={null}>
                  <em>Todas</em>
                </MenuItem>
                {Object.entries(CATEGORIAS).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel>Subcategoria</InputLabel>
              <Select
                value={subcategoria || null}
                label="Subcategoria"
                onChange={handleSubcategoriaChange}
                disabled={!categoriaPrincipal}
              >
                <MenuItem value={null}>
                  <em>Todas</em>
                </MenuItem>
                {getFilteredSubcategorias().map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {SUBCATEGORIAS[value]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="multiple-users-label">Utilizadores</InputLabel>
              <Select
                labelId="multiple-users-label"
                id="multiple-users"
                multiple
                value={users}
                onChange={handleUsersChange}
                input={<OutlinedInput label="Utilizadores" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => {
                      const user = allUsers.find((u) => u._id === value);
                      return (
                        <Chip key={value} label={user ? user.name : value} />
                      );
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {allUsers.map((user) => (
                  <MenuItem
                    key={user._id}
                    value={user._id}
                    style={getStyles(user._id, users, theme)}
                  >
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="logs__searchButtons__container">
              <div
                className="logs__searchButtons__btn logs__searchButtons__searchBtn"
                onClick={handleSearch}
              >
                <SearchIcon className="logs__searchButtons__searchBtn__icon" />
                <span className="logs__searchButtons__searchBtn__text">
                  Pesquisar
                </span>
              </div>
              <span
                className="logs__searchButtons__btn logs__searchButtons__cancelBtn"
                onClick={handleLimpar}
              >
                Limpar
              </span>
            </div>
          </div>

          {logsData && logsData.length > 0 ? (
            <>
              <LogsPagination
                totalPages={totalPages}
                page={page}
                onPageChange={handlePageChange}
              />

              <div className="logs-container">
                {logsData.map((log) => (
                  <div key={log._id} className="log-item">
                    {/* Header with timestamp and user */}
                    <div className="log-header">
                      <span className="log-timestamp">
                        {moment(log.data).format("DD/MM/YYYY HH:mm:ss")}
                      </span>
                      <span className="log-user">
                        {" -> " + log.utilizador.name}
                      </span>
                    </div>

                    {/* Main content */}
                    <div className="log-content">
                      {/* Action icon */}
                      <div className="log-icon">
                        {log.tipo === "CRIACAO" && (
                          <AddCircleIcon className="log-icon.create" />
                        )}
                        {log.tipo === "ALTERACAO" && (
                          <EditIcon className="log-icon.edit" />
                        )}
                        {log.tipo === "REMOCAO" && (
                          <DeleteIcon className="log-icon.delet" />
                        )}
                      </div>

                      {/* Log details */}
                      <div className="log-details">
                        <p className="log-description">{log.descricao}</p>

                        {log.detalhes && (
                          <div className="log-changes">
                            {log.tipo === "ALTERACAO" &&
                              log.detalhes.alteracoes && (
                                <>
                                  {Object.entries(log.detalhes.alteracoes).map(
                                    ([campo, valores]) => (
                                      <div key={campo} className="change-item">
                                        <div className="">
                                          <span className="">{campo} : </span>
                                          <span className="change-old">
                                            {valores.anterior}
                                          </span>
                                        </div>
                                        <div className="text-gray-800">
                                          <span className=""> → </span>
                                          <span className="change-new">
                                            {valores.novo}
                                          </span>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <LogsPagination
                totalPages={totalPages}
                page={page}
                onPageChange={handlePageChange}
              />
            </>
          ) : (
            <div className="logsNoResults__container">
              <SearchIcon className="logsNoResults__icon" />
              <span className="logsNoResults__message">
                Não foram encontrados registos.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Logs;
