import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import RoleSelector from "../roleSelector/RoleSelector";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { ClinicaContext } from "../../context/clinica-context";
import { NotificationsContext } from "../../context/notification-context";
import Dashboard from "@mui/icons-material/Dashboard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationsPopover from "./NotificationsPopover/NotificationsPopover";
import MenuNotifications from "./MenuNotifications/MenuNotifications";
import "./Navbar.scss";

import { useWebSocketSubscription } from "../../context/websocket-context";

function Navbar({
  title = "Pagina",
  icon = Dashboard,
  paths = [],
  btn = false,
  iconBtn = "",
  medicos,
  avaliacaoDispensada = null,
}) {
  const [isOnline, setIsOnline] = useState(true);
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const {
    notifications,
    addNotification,
    removeNotification,
    clearAllNotifications,
    fetchNotifications,
  } = useContext(NotificationsContext);
  const navigate = useNavigate();
  const Icon = icon;
  const BtnIcon = iconBtn;
  const [openNotifications, setOpenNotifications] = useState(false);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const lastNotificationIdRef = useRef(null);

  const logout = () => {
    navigate("/");
    auth.logout();
  };

  useEffect(() => {
    if (clinica?.clinica?._id) {
      fetchNotifications(clinica.clinica._id);
    }
  }, [clinica?.clinica?._id, fetchNotifications]);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleDispensarNotificacao = useCallback(
    async (notificationId) => {
      removeNotification(notificationId);
      if (avaliacaoDispensada) {
        avaliacaoDispensada(notificationId);
      }
    },
    [removeNotification, avaliacaoDispensada]
  );

  const handleClickNotificacao = useCallback(
    async (idNotificacao, tipo, idCliente, dados) => {
      removeNotification(idNotificacao);

      switch (tipo) {
        case "espera avaliação":
          navigate(`/clientes/${idCliente}-avaliacao`);
          break;
        case "espera procedimento":
          navigate(`/clientes/${idCliente}-historico`);
          break;
        case "espera consulta":
          navigate(`/clientes/${idCliente}`);
          break;
        case "efetuou avaliação":
          // navigate(`/clientes/${idCliente}-avaliacao`);
          navigate(
            auth.role === "Secretária(o)"
              ? `/orcamentos/new/${dados.avaliacaoId}`
              : `/clientes/${idCliente}-avaliacao`
          );
          break;
        default:
          navigate(`/clientes/${idCliente}`);
      }
    },
    [auth.role, navigate, removeNotification]
  );

  const handleCloseNotification = useCallback(() => {
    setOpenNotifications(false);
  }, []);

  const handleOpenNotifications = useCallback((e) => {
    setAnchorElNotifications(e.currentTarget);
    setOpenNotifications(true);
  }, []);

  // Material UI Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const btnOnClickHandler = () => {
    if (btn.link) {
      navigate(btn.link);
    }
  };

  useWebSocketSubscription(
    async (parsedSocketData) => {
      if (
        parsedSocketData.tabela === "notificacaos" &&
        parsedSocketData.documento.user.includes(auth.userId) &&
        parsedSocketData.documento._id !== lastNotificationIdRef.current
      ) {
        lastNotificationIdRef.current = parsedSocketData.documento._id;

        try {
          const responseData = await fetch(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/${parsedSocketData.documento.cliente}`,
            {
              headers: {
                Authorization: "Bearer " + auth.token,
              },
            }
          ).then((res) => res.json());
          let mensagemNaNotificacao = "";

          if (parsedSocketData.tipoDeOperacao === "insert") {
            mensagemNaNotificacao = parsedSocketData.documento.mensagem;
          }

          const newNotification = {
            cliente: {
              id: parsedSocketData.documento.cliente,
              _id: parsedSocketData.documento.cliente,
              nome: responseData.cliente.nome,
              foto: responseData.cliente.foto,
            },
            tipo: parsedSocketData.documento.tipo,
            id: parsedSocketData.documento._id,
            mensagem: mensagemNaNotificacao,
            dados: parsedSocketData.documento.dados,
          };

          addNotification(newNotification);
        } catch (err) {
          console.error("🔴 Error processing notification:", err);
        }
      }
    },
    [auth.userId, auth.token, auth.role, addNotification],
    "NavBar"
  );

  const handleMedicoChange = (e) => {
    navigate(`../${e.target.value}`);
  };

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="navbar__title">
          <Icon style={{ color: "#175c93", fontSize: "25px" }} />
          <span className="navbar__title__title">{title}</span>
          {medicos && (
            <select
              className="navbar__select-medicos"
              onChange={handleMedicoChange}
            >
              {medicos.map((m) => (
                <option value={m.id} key={m.id}>
                  {m.nome}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="mensagensImportantes">
          <span>{!isOnline && "Sem Conexão à Internet!"}</span>
        </div>
        <div className="items">
          {auth.listaRoles.length > 1 && (
            <div className="item">
              <RoleSelector />
            </div>
          )}
          <div className="item" style={{ cursor: "pointer" }}>
            <NotificationsPopover
              nrNotificacoes={notifications.length}
              onClick={handleOpenNotifications}
              anchorEl={anchorElNotifications}
            />
            <MenuNotifications
              anchorEl={anchorElNotifications}
              handleCloseNotification={handleCloseNotification}
              openNotification={openNotifications}
              notificacoes={notifications}
              handleDispensarNotificacao={handleDispensarNotificacao}
              handleClickNotificacao={handleClickNotificacao}
              handleApagarTodasNotificacoes={clearAllNotifications}
            />
          </div>
          <div className="navbar__item__userInfo">
            <img
              src={
                auth.image
                  ? `${process.env.REACT_APP_BACKEND_LINK}/${auth.image}`
                  : "/images/fallBackPicture.png"
              }
              alt=""
              className="avatar"
            />
            <div className="navbar__item__userInfo__nomeRole">
              <span className="navbar__item__userInfo__nomeRole__nome">
                {auth.nome}
              </span>
              <span className="navbar__item__userInfo__nomeRole__role">
                {auth.role}
              </span>
            </div>
            <KeyboardArrowDownIcon
              className="navbar__dropdownIcon"
              style={{ cursor: "pointer" }}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => navigate(`/users/${auth.userId}/perfil`)}
              >
                Perfil
              </MenuItem>
              <MenuItem onClick={logout}>Sair</MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      {paths.length > 0 && (
        <div className="navbar__path__wrapper">
          <div className="navbar__path">
            {paths.map((path, i) =>
              i === 0 ? (
                <Link to={path.link} key={i}>
                  <span className="navbar__path">{path.nome}</span>
                </Link>
              ) : (
                <div className="navbar__path-utility-div" key={i}>
                  <span className="navbar__icon__front">{">"}</span>
                  {i === paths.length - 1 ? (
                    <span className="navbar__path navbar__path__last">
                      {path.nome}
                    </span>
                  ) : (
                    <Link to={path.link}>
                      <span className="navbar__path">{path.nome}</span>
                    </Link>
                  )}
                </div>
              )
            )}
          </div>

          {btn && (
            <div className="navbar__buttons">
              <div className="big-btn">
                <BtnIcon className="big-btn-icon" />
                <span className="big-btn-text" onClick={btnOnClickHandler}>
                  {btn.text}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Navbar;
