import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import toast from "react-hot-toast";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import "./ModalEvolucao.scss";

function ModalEvolucao({
  openModalEvolucao,
  handleCloseModal,
  data,
  auth,
  sendRequest,
  updatePlanosTratamento,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedSessao, setSelectedSessao] = useState(null);
  const [dataInicioExecucao, setDataInicioExecucao] = useState(null);
  const [dataFimExecucao, setDataFimExecucao] = useState(null);
  const [evolucaoTexto, setEvolucaoTexto] = useState("");
  const [mensagemErro, setMensagemErro] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dados, setDados] = useState();
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  useEffect(() => {
    setDados(data.proc);
  }, [data]);

  console.log("data", data);
  const handleOpenModalEditarPlano = (sessao) => {
    setSelectedSessao(sessao);
    setDataInicioExecucao(moment(sessao.data_inicio_execucao));
    setDataFimExecucao(moment(sessao.data_fim_execucao));
    setEvolucaoTexto(sessao.evolucao);
    setIsEditing(true);
  };

  const handleConfirmDelete = async (sessaoId) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("sessaoId", sessaoId);
      const planoRetornado = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/eliminarsessao/${data.idPlano}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const tempProcedimento = {
        ...data.proc,
        sessoes: data.proc.sessoes.filter((s) => s._id !== sessaoId),
      };
      setDados(tempProcedimento);
      updatePlanosTratamento(planoRetornado.plano);
      toast.success("Sessão eliminada com sucesso");
      setIsEditing(false);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      setDeleteConfirmation(null);
    }
  };

  const submitHandler = async () => {
    let erro = "";
    if (evolucaoTexto === "" || !evolucaoTexto) {
      erro = "Introduza uma evolução";
    }
    if (!erro) {
      startLoading();
      try {
        const formData = new FormData();
        formData.append("evolucao", evolucaoTexto);
        formData.append("data_inicio_execucao", dataInicioExecucao);
        formData.append("data_fim_execucao", dataFimExecucao);
        formData.append("sessaoId", selectedSessao.id);
        formData.append("utilizador", auth.userId);

        const planoRetornado = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/editarsessao/${data.idPlano}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setDados(
          planoRetornado.plano.procedimentos.filter(
            (p) => p._id === data.proc._id
          )[0]
        );
        updatePlanosTratamento(planoRetornado.plano);
        toast.success("Sessão editada com sucesso");
        setIsEditing(false);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    } else {
      setMensagemErro(erro);
    }
    setIsSubmitting(false);
  };

  const handleDeleteClick = (sessaoId) => {
    setDeleteConfirmation(sessaoId);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmation(null);
  };

  const preSubmitHandler = () => {
    if (!dataInicioExecucao) {
      toast.error("Introduza a data de inicio de execução");
    } else if (
      !moment.isMoment(dataInicioExecucao) ||
      !dataInicioExecucao.isValid()
    ) {
      toast.error("Introduza a data de inicio de execução corretamente");
    } else if (!dataFimExecucao) {
      toast.error("Introduza a data de fim de execução");
    } else if (
      !moment.isMoment(dataFimExecucao) ||
      !dataFimExecucao.isValid()
    ) {
      toast.error("Introduza a data de fim de execução corretamente");
    } else if (moment(dataFimExecucao).isBefore(moment(dataInicioExecucao))) {
      toast.error(
        "A data de fim de execução não pode ser antes da data de inicio."
      );
    } else {
      setIsSubmitting(true);
      submitHandler();
    }
  };

  return (
    <Modal
      open={openModalEvolucao}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box"
        id={isEditing ? "dividas__modal" : "modal__evolucao"}
      >
        <h1 className="fluxo-atendimento__modal__titulo">
          {isEditing ? `Editar Sessão` : "Sessões"}
        </h1>
        {!isEditing ? (
          <div className="modalEvolucao__container">
            {dados &&
              dados.sessoes.map((sessao, index) => (
                <div className="modalEvolucao__container__item" key={index}>
                  <div className="modalEvolucao__container__item--left">
                    <span className="modalEvolucao__container__item--left__evolucao">
                      {sessao.evolucao}
                    </span>
                    <span className="modalEvolucao__container__item--left__otherinfo">
                      {moment(sessao.data_inicio_execucao).format("DD-MM-YYYY")}
                      , {moment(sessao.data_inicio_execucao).format("HH:mm")} às{" "}
                      {moment(sessao.data_fim_execucao).format("HH:mm")}
                      {" - "}
                      {sessao.medico.genero === "masculino" ? "Dr. " : "Dra. "}
                      {sessao.medico.name}
                    </span>
                  </div>
                  <div className="modalEvolucao__container__btns">
                    {sessao.medico?._id === auth.userId && (
                      <>
                        <Tooltip title="Editar">
                          <IconButton
                            onClick={() => handleOpenModalEditarPlano(sessao)}
                            className="modalEvolucao__container__btns__icon-btn"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        {deleteConfirmation === sessao._id ? (
                          <div className="delete-confirmation">
                            <Tooltip title="Eliminar">
                              <IconButton
                                onClick={() => handleConfirmDelete(sessao._id)}
                              >
                                <CheckIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Cancelar">
                              <IconButton onClick={handleCancelDelete}>
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ) : (
                          <Tooltip title="Eliminar">
                            <IconButton
                              onClick={() => handleDeleteClick(sessao._id)}
                              className="modalEvolucao__container__btns__icon-btn"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <>
            <div className="adicionarEvolucaoContainer__d">
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DateTimePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data Inicial*"
                  inputFormat="DD-MM-YYYY HH:mm"
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => setDataInicioExecucao(value)}
                  value={dataInicioExecucao}
                />
              </LocalizationProvider>

              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DateTimePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data Final*"
                  inputFormat="DD-MM-YYYY HH:mm"
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => setDataFimExecucao(value)}
                  value={dataFimExecucao}
                />
              </LocalizationProvider>
            </div>
            <div className="adicionarEvolucaoContainer">
              <textarea
                className="executarProcedimento__container__textarea"
                placeholder="Escreva aqui a evolução do procedimento."
                cols="30"
                rows="5"
                value={evolucaoTexto}
                onChange={(e) => setEvolucaoTexto(e.target.value)}
              />
            </div>
            {mensagemErro && (
              <div className="modalEvolucaoErroContainer">{mensagemErro}</div>
            )}
          </>
        )}
        <div
          className={isEditing ? "" : "fluxo-atendimento__modal__bottom_btns"}
          id={isEditing ? "" : "modalEvolucao__btns"}
          style={
            isEditing
              ? {
                  gridColumn: "1/-1",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "25px",
                }
              : {}
          }
        >
          {isEditing ? (
            <>
              <span
                disabled={isSubmitting}
                className="blue-button"
                onClick={preSubmitHandler}
                style={{ marginRight: "10px" }}
              >
                {isSubmitting ? "Guardando..." : "Guardar"}
              </span>
              <span className="cancel-btn" onClick={() => setIsEditing(false)}>
                Fechar
              </span>
            </>
          ) : (
            <span
              className="cancel-btn"
              onClick={handleCloseModal}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default ModalEvolucao;
