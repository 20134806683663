import { useState, useEffect, useContext } from "react";
import moment from "moment";
import InputSelect from "../../../../../shared/components/inputs/InputSelect/InputSelect";

import { ClinicaContext } from "../../../../../shared/context/clinica-context";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";

import "./ModalEntrada.scss";

const formasPagamento = [
  "Dinheiro",
  "Cheque",
  // "Cheque Visado",
  // "Transferência de crédito",
  "Internet banking",
  // "Cartão do banco",
  // "Débito direto da conta bancária",
  "Cartão de crédito",
  // "Cartão de débito",
];

function ModalReceberEntrada({
  openModalReceberEntrada,
  handleCloseModalReceberEntrada,
  sendRequest,
  dadosModalReceberEntrada,
  updateEntradas,
  auth,
  clinicaId,
  emitirDocEletronicoReembolso,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(openModalReceberEntrada);
  const [comprovativo, setComprovativo] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [metodoPagamento, setMetodoPagamento] = useState(formasPagamento[0]);
  const [dataPagamento, setDataPagamento] = useState(moment());
  const [caixa, setCaixa] = useState(
    dadosModalReceberEntrada.caixa._id
      ? dadosModalReceberEntrada.caixa._id
      : dadosModalReceberEntrada.caixa.id
  );
  const [caixas, setCaixas] = useState([]);

  useEffect(() => {
    const fetchCaixas = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setCaixas(
          responseData.caixas.map((cai) => {
            return { id: cai.id, value: cai.caixa };
          })
        );
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchCaixas();
  }, [auth.token, clinicaId, sendRequest]);

  const clinica = useContext(ClinicaContext);

  useEffect(() => {
    setOpenModal(openModalReceberEntrada);
  }, [openModalReceberEntrada]);

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const receberEntradaHandler = async (emit) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("dataPagamento", dataPagamento);
      formData.append("metodoPagamento", metodoPagamento);
      formData.append("recebidoPor", auth.userId);
      formData.append("caixa", caixa);
      formData.append(
        "caixaExtenso",
        caixas.find((cx) => cx.id === caixa).value
      );

      if (comprovativo) {
        const modifiedFile = new File(
          [comprovativo],
          `${clinica.clinica._id}-${comprovativo.name}`,
          { type: comprovativo.type }
        );
        formData.append("comprovativo", modifiedFile);
      }

      //LEMBRA DE ADICIONAR COMPROVATIVO MAIS TARDE
      let entradaAtualizada;
      try {
        entradaAtualizada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/receber/${dadosModalReceberEntrada.id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      if (emit) {
        if (dadosModalReceberEntrada.tipo === "clienteFornecedor") {
          updateEntradas(entradaAtualizada.entrada, "atualizar", emit);
        } else {
          emitirDocEletronicoReembolso(
            entradaAtualizada.entrada,
            null,
            "FRE",
            "atualizar"
          );
        }
      } else {
        toast.success("Entrada recebida com sucesso!");
        updateEntradas(entradaAtualizada.entrada, "atualizar");
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      handleCloseModalReceberEntrada();
    }
  };

  const preReceberEntradaHandler = (e, emit) => {
    if (!dataPagamento) {
      toast.error("Introduza a data de recebimento");
    } else if (!moment.isMoment(dataPagamento) || !dataPagamento.isValid()) {
      toast.error("Introduza a data de recebimento corretamente");
    } else if (!isSubmitting && !errorMessage) {
      setIsSubmitting(true);
      receberEntradaHandler(emit);
    }
  };

  const handleFileChange = (event) => {
    // Check file size (3MB limit)
    const maxSizeInBytes = 7 * 1024 * 1024; // 7MB in bytes
    const file = event.target.files[0];
    // Check file extension
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
    if (!allowedExtensions.test(file.name)) {
      setErrorMessage(`O ficheiro "${file.name}" tem uma extensão inválida.`);
    } else if (file.size > maxSizeInBytes) {
      setErrorMessage(
        `O ficheiro "${file.name}" excede o tamanho máximo permitido (7MB).`
      );
    } else {
      setErrorMessage("");
      setComprovativo(file);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalReceberEntrada}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-entrada__modal"
          id="modal__adicionar-entrada"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Receber entrada{" "}
            {" - " + dadosModalReceberEntrada.tipo === "reembolso"
              ? "de Cliente"
              : "de Cliente/Fornecedor"}
          </h1>
          {/* {entrada && ( */}
          <div className="adicionar-entrada__modal__container">
            <div className="customInputContainer adicionar-despesa__modal__container--span2">
              <input
                type="text"
                defaultValue={dadosModalReceberEntrada.descricao}
                className="customInput inputNumber"
                id="descricao"
                style={{ pointerEvents: "none", color: "#88959e" }}
                readOnly
              />
              <label className="customInputLabel" htmlFor="descricao">
                Descrição
              </label>
            </div>

            <div className="customInputContainer adicionar-despesa__modal__container--span1">
              <input
                type="text"
                defaultValue={dadosModalReceberEntrada.valor}
                className="customInput inputNumber"
                id="valor"
                style={{ pointerEvents: "none", color: "#88959e" }}
                readOnly
              />
              <label className="customInputLabel" htmlFor="valor">
                Valor
              </label>
            </div>

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data do Pagamento*"
                inputFormat="DD-MM-YYYY"
                value={dataPagamento}
                onChange={(value) => setDataPagamento(value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <div className="customInputContainer adicionar-despesa__modal__container--span2">
              <input
                type="text"
                defaultValue={dadosModalReceberEntrada.categoria.categoria}
                className="customInput inputNumber"
                id="categoria"
                style={{ pointerEvents: "none", color: "#88959e" }}
                readOnly
              />
              <label className="customInputLabel" htmlFor="categoria">
                Categoria
              </label>
            </div>

            <div className="customInputContainer adicionar-despesa__modal__container--span2">
              <input
                type="text"
                defaultValue={
                  dadosModalReceberEntrada.clienteFornecedor?.nome ||
                  dadosModalReceberEntrada.cliente?.nome
                }
                className="customInput inputNumber"
                id="clienteFornecedor"
                style={{ pointerEvents: "none", color: "#88959e" }}
                readOnly
              />
              <label className="customInputLabel" htmlFor="clienteFornecedor">
                {dadosModalReceberEntrada.cliente
                  ? "Cliente"
                  : "Cliente/Fornecedor"}
              </label>
            </div>

            <textarea
              cols="30"
              rows="7"
              placeholder="observacao"
              value={dadosModalReceberEntrada.observacao}
              readOnly
              className="adicionar-entrada__modal__container__textarea"
              style={{ color: "#88959e" }}
            ></textarea>
          </div>

          <div
            className="adicionar-despesa__modal__pagamento"
            style={{ marginTop: 25, marginBottom: 25 }}
          >
            <InputSelect
              className="adicionar-entrada__modal__container--span1"
              label="Forma de pagamento"
              initialValue={metodoPagamento}
              handleChange={metodoPagamentoChangeHandler}
              items={formasPagamento}
            />

            {caixas && caixa && (
              <div className="customInputContainer adicionar-entrada__modal__container--span1">
                <select
                  onChange={(e) => setCaixa(e.target.value)}
                  className="customInputSelect"
                  value={caixa}
                >
                  {caixas &&
                    caixa &&
                    caixas.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Caixa</label>
              </div>
            )}
          </div>
          {comprovativo && (
            <div className="despesas--comprovativo-selecionado">
              Comprovativo Selecionado: {comprovativo.name}
            </div>
          )}
          {dadosModalReceberEntrada.tipo !== "reembolso" && (
            <>
              <div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="fileInput"
                  onChange={handleFileChange}
                />
                <label htmlFor="fileInput" className="anexar-comprovativo">
                  {comprovativo
                    ? "Substituir comprovativo"
                    : "Anexar comprovativo"}
                </label>
              </div>

              <div className="despesas--regras-ficheiro">
                <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                  O Tamanho máximo do upload é 7MB
                </span>
                <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                  Formatos aceites: .pdf, .png, .jpg e .jpeg
                </span>
              </div>
            </>
          )}

          {errorMessage && (
            <div className="modal-mensagem-de-erro">
              <span>{errorMessage}</span>
            </div>
          )}
          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalReceberEntrada}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              disabled={isSubmitting || errorMessage}
              className="blue-button"
              onClick={preReceberEntradaHandler}
            >
              {isSubmitting ? "Recebendo..." : "Receber"}
            </span>

            {/* Emitir RCE - se a entrada estiver recebida e não houver nenhum erro nem no documento fiscal, no recibos eletronicos. (PENSAR EM CASO DE ERRO NO CANCELAMENTO) */}
            {/* {dadosModalReceberEntrada.documentoEletronico?.succeeded === true &&
              !dadosModalReceberEntrada?.faturaEReciboEletronicos?.rce
                ?.succeeded === true && (
                <span
                  disabled={isSubmitting || errorMessage}
                  className={
                    isSubmitting || errorMessage ? "cancel-btn" : "blue-button"
                  }
                  onClick={preReceberEntradaHandler.bind(
                    null,
                    null,
                    "emitirRCE"
                  )}
                >
                  {isSubmitting ? "Recebendo..." : "Receber e emitir RCE"}
                </span>
              )} */}

            {/*Emitir FRE*/}
            {!dadosModalReceberEntrada.documentoEletronico &&
              dadosModalReceberEntrada.tipo === "reembolso" && (
                <span
                  disabled={isSubmitting || errorMessage}
                  className={
                    isSubmitting || errorMessage ? "cancel-btn" : "blue-button"
                  }
                  onClick={preReceberEntradaHandler.bind(
                    null,
                    null,
                    "emitirFRE"
                  )}
                >
                  {isSubmitting
                    ? "Recebendo e emitindo FRE..."
                    : "Receber e emitir FRE"}
                </span>
              )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalReceberEntrada;
